import { installationPartnerRealmEnvironment } from '@abb-emobility/oms/installation-partner-oauth';

export const environment = {
	production: true,
	// oauthRealm is set before feeding the env provider in the main component
	oauthRealm: '',
	oauthClientId: '',
	// installationPartnerPath is set before feeding the env provider in the main component
	installationPartnerPath: '',
	installationPartnerRealms: installationPartnerRealmEnvironment
};
